import { g as getWidthFromResizeObserverEntry } from "./ComponentUtils-7e5aeb7a.js";
import "./element-d66cb8c2.js";
import { b as ResizeObserverSafe } from "./MutationObserverSafe-8cdfc100.js";
function OnElementResize(e) {
  return function (r, i) {
    var n = r.componentDidLoad,
      t = r.disconnectedCallback,
      o = r.connectedCallback;
    var v = "".concat(i, "-resize-observer");
    var s = function (e, r) {
      var n = r.target;
      var t;
      var o = n(e);
      if (o) {
        var s = e[i];
        (t = e[v]) === null || t === void 0 ? void 0 : t.disconnect();
        e[v] = new ResizeObserverSafe(function (r, i) {
          for (var n = 0, t = r; n < t.length; n++) {
            var o = t[n];
            s === null || s === void 0 ? void 0 : s.call(e, getWidthFromResizeObserverEntry(o));
          }
        });
        e[v].observe(o);
      }
    };
    r.componentDidLoad = function () {
      s(this, e);
      return n === null || n === void 0 ? void 0 : n.call(this);
    };
    r.connectedCallback = function () {
      s(this, e);
      return o === null || o === void 0 ? void 0 : o.call(this);
    };
    r.disconnectedCallback = function () {
      var e;
      (e = this[v]) === null || e === void 0 ? void 0 : e.disconnect();
      return t === null || t === void 0 ? void 0 : t.call(this);
    };
  };
}
export { OnElementResize as O };